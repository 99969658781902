import icsToJson from './icalToJSON'
import pickmeup from 'pickmeup'
import { splitColor } from 'gsap';

// Mehrsprachiger Kalender
pickmeup.defaults.locales['de'] =
{
  days : ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
  daysShort : ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
  daysMin : ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  months : ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
  monthsShort : ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
};

pickmeup.defaults.locales['en'] =
{
  days : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  daysShort : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  daysMin : ['Su', 'Mo', 'Tu', 'Tu', 'We', 'Fr', 'Sa'],
  months : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  monthsShort : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
};

pickmeup.defaults.locales['es'] =
{
  days : ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  daysShort : ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
  daysMin : ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'], 
  months : ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Deciembre'],
  monthsShort : ['Ene', 'Feb', 'Mar', 'Apb', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec']
};

pickmeup.defaults.locales['pl'] =
{
  days : ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
  daysShort : ['Niedz', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'So'],
  daysMin : ['Ni', 'Po', 'Wt', 'Śr', 'Czw', 'Pt', 'So'],
  months : ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
  monthsShort : ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru']
};



const ical = {
    cfg: {
      now : new Date(),
      today : (new Date()).toLocaleDateString(),
      calendar : document.querySelector('.js-calendar')
    },

    init() {

      async function checkBelegung(object, loopDate){

        let start, end;
        // belegung
        for(element in object){

          start = object[element].startDate.split('T')[0];
          end = object[element].endDate.split('T')[0];

          if( (loopDate >= start ) && (loopDate <= end )){
            // console.group(loopDate);
            // console.log('start: ' + start);
            // console.log('end:' + end);
            // console.groupEnd();

            return {disabled : true, class_name : 'date-not-free'};
          }
        }
      }

      function addMonths(numOfMonths, date) {
        date.setMonth(date.getMonth() + numOfMonths);
        return date;
      }

      const now = new Date();
      const today = (new Date()).toLocaleDateString();
      
        window.addEventListener('load', () => {

          const calendar = document.querySelector('.js-calendar');
          if(calendar) {
            fetch(window.location.href + '/calendar.ics')
            .then(response => response.text())
            .then(data => {
                
                // convert ICAL to json
                const json = icsToJson(data);
                // console.log(json)

                // init Calendar
                pickmeup(calendar, {
                  flat : true,
                  mode : 'range',
                  select_month: false,
                  select_year: false,
                  view : 'days',
                  calendars: 3,
                  locale : document.documentElement.lang,
                  current: addMonths(1, new Date()),
                  seperator : '-',
                    render : function (date) {
                      
                      let loopDate = date.valueOf();
                      let css_classes = [];
                      let start;
                      let end;

                      loopDate = new Date(loopDate);
                      loopDate = loopDate.getFullYear() + '' + ('0' + (loopDate.getMonth()+1)).slice(-2) + '' + ('0' + loopDate.getDate()).slice(-2)
                  
                      // Datum liegt in Vergangenheit
                      if (date < now) {
                        return {disabled : true, class_name : 'date-in-past'};
                      }

                      // belegung
                        for(element in json){

                          start = json[element].startDate.split('T')[0];
                          end = json[element].endDate.split('T')[0];

                          // console.log(start);
                          // console.log(end);
                          // console.log('_________________________');

                          if( (loopDate >= start ) && (loopDate <= end )){
                            // console.group(loopDate);
                            // console.log('start: ' + json[element].startDate.split('T')[0]);
                            // console.log('end:' + json[element].endDate.split('T')[0]);
                            // console.groupEnd();

                            return {disabled : true, class_name : 'date-not-free'};
                          }
                        }
                } 
              });
            }).catch(function() {
              console.log("error");
          });;
        }
        })
    }
}

export default ical
