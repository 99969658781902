/**
 * Bundle Scripts
 */

// Polyfills
import 'babel-polyfill';
import 'svgxuse';
import Alpine from 'alpinejs'

// Init AlpineJS for funky Frontend Shit
window.Alpine = Alpine
// import objectFitImages from 'object-fit-images';

// Import Partials
import scripts from './partials/_scripts';
import components from './partials/_components';

const app = {
  init() {
    scripts.init();
    components.init();
    Alpine.start()
    // objectFitImages();
  },
};

// Init App!
app.init();
