/**
 * Components
 */

import objects from '../../../site/templates/components/object/object'
import objectGrid from '../../../site/templates/builder/blocks/objects/_script'
import slider from '../../../site/templates/components/slider/slider'
import sliderObjects from '../../../site/templates/components/slider/objects'
import gallery from '../../../site/templates/components/gallery/gallery'
import map from '../../../site/templates/components/map/map'
import ical from '../components/_ical'
import '../components/menu/index'

// Modules Array
const componentsArray = [
  objects,
  objectGrid,
  slider,
  sliderObjects,
  map,
  gallery,
  ical
]

const components = {
  // components
  Components() {
    if (componentsArray.length > 0 && componentsArray !== undefined) {
      componentsArray.forEach((component) => {
        component.init()
      })
    }
  },

  init() {
    this.Components()
  }
}

export default components
