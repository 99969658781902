import Flickity from 'flickity'

const objects = {
  cfg: {
    name: 'objects',
    sliderOptions: {
      cellAlign: 'center',
      // contain: true,
      draggable: true,
      friction: 1,
      lazyLoad: 2,
      selectedAttraction: 0.1,
      // groupCells: 1,
      setGallerySize: false,
      imagesLoaded: false,
      prevNextButtons: true,
      pageDots: false,
      wrapAround: true,
      arrowShape: { 
        x0: 10,
        x1: 60, y1: 50,
        x2: 70, y2: 50,
        x3: 20
      }
    }
  },

  init() {
    window.addEventListener('load', () => {

        const sliders = [...document.querySelectorAll('.js-object-slider')]

        if (sliders) {
          sliders.forEach((slider) => {
            const flkty = new Flickity(slider, this.cfg.sliderOptions)
            
            setTimeout(() => {
              slider.classList.remove('opacity-0')
              slider.classList.add('is-ready')
              flkty.offsetHeight;
            }, 300)
          })
        }
    })
  }
}

export default objects
