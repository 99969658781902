import Masonry from 'masonry-layout'

const objects = {
  cfg: {
    name: 'objects',
    sliderOptions: {
      cellAlign: 'center',
      // contain: true,
      draggable: true,
      friction: 1,
      lazyLoad: 2,
      selectedAttraction: 0.1,
      // groupCells: 1,
      setGallerySize: false,
      imagesLoaded: false,
      prevNextButtons: true,
      pageDots: false,
      wrapAround: true
    }
  },

  init() {
    window.addEventListener('load', () => {
      const masonrys = document.querySelector('.js-masonry');

        if (masonrys) {
          let masonry = new Masonry('.js-masonry', {
            itemSelector: '.item',
            transitionDuration: 0,
          })

          setTimeout(() => {

            window.dispatchEvent(new Event('resize'));
          }, 1000)
        }
      })
  }
}

export default objects
