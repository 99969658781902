const map = {
    cfg: {
      name: 'c-map',
      cn: '.c-map',
      marker: '/assets/img/marker.png'
    },
  
    createMapHead() {
      const jsCDN = 'https://api.tiles.mapbox.com/mapbox-gl-js/v2.8.2/mapbox-gl.js';
      const cssCDN = 'https://api.tiles.mapbox.com/mapbox-gl-js/v2.8.2/mapbox-gl.css';
  
      let scriptTag = document.createElement("script");
      var linkTag = document.createElement("link");
  
      scriptTag.type = "text/javascript";
      linkTag.rel = "stylesheet";
  
      scriptTag.src = jsCDN;
      linkTag.href = cssCDN;
  
      document.getElementsByTagName("head")[0].appendChild(scriptTag);
      document.getElementsByTagName("head")[0].appendChild(linkTag);
  
      return false;
    },
  
    loadMapbox() {
      mapboxgl.accessToken =
      'pk.eyJ1Ijoic3Rhcmtjb2RlcyIsImEiOiJjam9wemc4ZzEwMW12M2twMnZ6ODM3dThpIn0.D8t6MpHi-9gLGA7hFyzYuQ';
      var bounds = new mapboxgl.LngLatBounds();
  

      const reg_exp = /\[[^(\]\[)]*\]/g
      const mapElement = document.getElementById('map');
      const markers_addresses = mapElement.getAttribute('data-addresses').match(reg_exp)
      const markers_links = mapElement.getAttribute('data-links').split('|');
      const markers_titles = mapElement.getAttribute('data-titles').split('|');
      

      let geojson = {};
      let features = [];

      

   

    for (var i = 0; i < markers_addresses.length; i++) {
    features.push({
        type: 'Feature',
        geometry: {
            'type': 'Point',
            'coordinates': JSON.parse(markers_addresses[i])
        },
        'properties': {
            'title': markers_titles[i],
            'description': markers_links[i]
        }
    });

   

    }
// BUILD JSOM
      geojson.type = 'FeatureCollection'; 
      geojson.features = features; 
      geojson.features.forEach(function (feature) {

          // console.log(feature.geometry.coordinates);

          bounds.extend(feature.geometry.coordinates);
      });

     
  
      const map = new mapboxgl.Map({
          container: 'map',
          style: 'mapbox://styles/starkcodes/cl3vbqkl9000c14mi83ybl1ps',
          center: [11.127700575227077, 53.75790378151968],
          maxZoom: 11,
          minZoom: 4,
          zoom: 12, 
          maxBounds:  [[2.38865807458, 45.3024876979],
                      [17.0169958839,57]]
      });
  
      // add markers to map
      for (const feature of geojson.features) {
          // create a HTML element for each feature
          const el = document.createElement('div');
          el.className = 'marker';
  
          var markerWidth = 8;
          var markerHeight = 8;
  
          el.style.width = markerWidth + 'px';
          el.style.height = markerHeight + 'px';
  
          // make a marker for each feature and add it to the map
          new mapboxgl.Marker(el)
              .setLngLat(feature.geometry.coordinates)
              .setPopup(
                  new mapboxgl.Popup({
                      offset: 25,
                      closeOnClick: false
                  }) // add popups
                  .setHTML(
                      `<div class="text-center"><h3>${feature.properties.title}</h3><a target="_blank" rel="noopener" class="text-xs mt-2 block" href="https://www.google.com/maps/dir//${feature.geometry.coordinates[1]},${feature.geometry.coordinates[0]}/">Route berechnen →</a></div>`
                    //    +
                    //   `<div class="flex gap-2 justify-around mt-3">
                    //     <a href="">
                    //         <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    //         <path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    //         </svg>
                    //     </a>
                    //     <a href="">
                    //         <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    //             <path stroke-linecap="round" stroke-linejoin="round" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
                    //         </svg>
                    //     </a>
                    //   </div>`
                    
                )
              )
              .addTo(map)
              // .togglePopup();
              // Toogle Popup triggers LayoutShift?!?!?!
      }
  
      map.fitBounds(bounds, {
          padding: {
              top: 160,
              bottom: 120,
              left: 40,
              right: 40
          }
      });
  
    //   map.setMaxBounds(bounds);
  
    //   map.fitBounds([5.98865807458, 49.3024876979, 15.0169958839, 54.983104153], {
    //       padding: {
    //           top: 180,
    //           bottom: 40,
    //           left: 40,
    //           right: 40
    //       }
    //   });
  
      map.scrollZoom.disable();
      // map.touchZoomRotate.enable();
    },
  

    loadEverything(container, tagsVisible){
        container.classList.add('has-privacy-accepted');
        
        if (!tagsVisible) {
            this.createMapHead();
        }

        setTimeout(() =>{
            this.loadMapbox();
        },300)
    },

    init() {



      window.addEventListener('load', () => {
          const button = document.querySelector('[data-privacy-button]');
          const container = document.querySelector('.js-map');
          const mapboxAllowed = container.getAttribute('data-mapbox');
          let tagsVisible;

          if(mapboxAllowed){
            this.loadEverything(container, tagsVisible);
          } else {
            if (button) {
                tagsVisible = false;
                button.onclick = () => {
                    this.loadEverything(container, tagsVisible);
                    tagsVisible = true;
                };
            }
          }
      })
    }
  }
  
  export default map;




