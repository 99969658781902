import Flickity from 'flickity'

const slider = {
  cfg: {
    name: 'slider',
    sliderOptions: {
      cellAlign: 'center',
      // contain: false,
      // draggable: true,
      friction: 1,
      // lazyLoad: 2,
      selectedAttraction: 0.1,
      setGallerySize: false,
      // imagesLoaded: true,
      prevNextButtons: true,
      pageDots: false,
      wrapAround: true,
      arrowShape: { 
        x0: 10,
        x1: 60, y1: 50,
        x2: 70, y2: 50,
        x3: 20
      }
    }
  },

  init() {
    window.addEventListener('load', () => {

        const sliders = [...document.querySelectorAll('.js-slider')]

        if (sliders) {
          sliders.forEach((slider) => {
            const flkty = new Flickity(slider, this.cfg.sliderOptions)
            flkty.resize()
  
            setTimeout(() => {
              flkty.resize()
              slider.classList.add('is-ready')
            }, 1000)
          })
        }
    })
  }
}

export default slider
